*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.app-bar {
  width: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  max-height: 60px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 10%, rgba(0, 0, 0, 0));
  background-color: transparent;
  opacity: 0.9;
}
.app-bar .logo-container {
  display:flex;
  flex-direction: row;
  padding: 15px;
  align-items: center;
}

.app-bar .logo-container img {
  max-height: 40px;
  margin: 0 5px;
}

.app-bar .logo-container h1 {
  color: #fff;
  font-size: 1.5em;
  margin: 0 5px;
}

.section-full {
  display: flex;
  width: 100%;
}

.section-inner {
  margin:auto;
  max-width: 900px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section-header {
  padding: 10px 50px 10px;
}

.section-header h1 {
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.5);
  text-align: center;
  font-size: 3em;
}

.section-content {
  text-align: center;
  padding: 10px 50px 50px;
}

.section-content p {
  margin-bottom: 15px;
}

.section-content img {
  max-width: 30%;
}